<template>
  <router-link 
    class="my-applications__border my-applications__tab-box" 
    :to="{ name: 'application-create', params: { id: application.program_id }, query: { app_id: application.id, user_id: application.user.id } }"
  >
    <img class="my-applications__tab-box__logo" :src="application.program_logo"/>
    <div class="my-applications__tab-box__container">
      <div class="name">
        <span><b>{{ application.user.full_name }}</b></span><br>
        <span>{{ application.program }}</span>
      </div>
      <div>
        <span>{{ sessionSemester }} Dates</span><br>
        <template v-if="application.sessions.length">
          <span v-for="session in application.sessions" :key="session.id">
            <b class="date">{{ session.start_date }} - {{ session.end_date }}</b>
            <br>
          </span> 
        </template>
        <span v-else>{{ sessionSemester }} is not selected</span>
      </div>
      <div>
        <span>Status</span><br>
        <span><b>{{ application.has_unpaid_failed_transaction ? 'Payment Failed' : application.status }}</b></span>
      </div>
    </div>
<!--    <b-badge-->
<!--      v-if="application.has_unpaid_failed_transaction"-->
<!--      class="my-applications__tab-box__badge"-->
<!--      variant="primary"-->
<!--    >Payment Failed</b-badge>-->
  </router-link>
</template>

<script>
import { instanceSessionCamperNames } from '@core/mixins/instanceSessionCamperNames'

export default {
  props: {
    application: {
      type: Object,
      default: () => {
      },
    },
  },
  mixins: [instanceSessionCamperNames],
}
</script>

<style scoped>

</style>